import React from "react";

const Footer = () => (
    <footer>
        <div className="container">
            <div className="row align-items-start gap-5">
                <div className="d-none d-md-flex col-auto">
                    <dl>
                        <dd><a href="/">Home</a></dd>
                        <dd><a href="/">Privacy policy</a></dd>
                        <dd><a href="/">Terms and services</a></dd>
                    </dl>
                </div>
                <div className="col-12 col-md d-flex align-items-center justify-content-center">
                    <i className="bx bxl-twitter bx-sm"></i>
                    <a href="https://x.com/thepublicbay" className="ms-1" target="_blank" rel="noreferrer">@thepublicbay</a>
                </div>
                <div className="col-12 col-md-auto text-center text-lg-end">&copy; 2024 - thepublicbay</div>
            </div>
        </div>
    </footer>
);

export default Footer;
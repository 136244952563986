import React from "react";

const Header = () => (
    <nav className="navbar fixed-top bg-transparent">
        <div className="container">
            <a href="/" className="navbar-brand">
                <img src="logo.svg" width="50" height="60" alt="The Public Bay logo" />
            </a>
        </div>
    </nav>
);

export default Header;
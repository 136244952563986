import React, { useState } from 'react';

import { logEvent } from 'firebase/analytics';
import { collection, doc, setDoc } from 'firebase/firestore';
import { analytics, db } from '../utils/firebase';

const Input = () => {
    const [email, setEmail] = useState('');
    const [processMsg, setProcessMsg] = useState('');
    const [processStatus, setProcessStatus] = useState('valid');

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleClick = () => {
        setProcessMsg('');
        setProcessStatus('valid');

        const RMAIL = new RegExp(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        );

        if (!RMAIL.test(email)) {
            logEvent(analytics, 'subscribe_wrong_format');
            setProcessStatus('invalid');
            setProcessMsg('Please provide a valid email');
            return;
        }

        setDoc(doc(collection(db, 'emails')), {
            email,
            insertAt: new Date(),
        }).then(() => {
            logEvent(analytics, 'subscribe_success');
            setProcessStatus('valid');
            setProcessMsg('Thank you for joining us!');
        }).catch((err) => {
            logEvent(analytics, 'subscribe_error');
            setProcessStatus('invalid');
            setProcessMsg('An error occured: ' . err.message);
        });
    };

    return (
        <div className="input-group has-validation mt-1">
            <input type="email" className="form-control border-primary" placeholder="Enter your email" value={email} onChange={handleChange} />
            <button type="button" className={`btn btn-primary is-${processStatus}`} onClick={handleClick}>Subscribe</button>
            <div className={`${processStatus}-feedback`}>{processMsg}</div>
        </div>
    );
}

export default Input;
import React from 'react';

// eslint-disable-next-line
import { analytics, perf } from './utils/firebase';
import { Header, Input, Footer } from './components';

const App = () => {
    const scrollTo = (selector) => {
        document.querySelector(selector).scrollIntoView();
    }
    return (
        <>
            <Header />

            <section id="hero">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 d-flex flex-column text-center gap-5 text-md-start gap-md-4">
                            <h1>The Public Bay</h1>

                            <dl>
                                <dd><em>Pitch</em> your vision</dd>
                                <dd><em>Validate</em> your idea</dd>
                                <dd>Make it a <em>thriving venture</em></dd>
                                <dd>&nbsp;</dd>
                                <dd>Not only in public, but <em>with public</em></dd>
                            </dl>

                            {/* CTA Button */}

                        </div>
                    </div>

                    {/* Temporary - Coming soon */}
                    <div id="soon" className="row">
                        <div className="col-12 col-lg-7">
                            <h3>Coming soon</h3>
                            <span className="text-3">Please subscribe to our newsletter to be updated when we go live</span>
                            <Input />
                        </div>
                    </div>
                </div>

                {/* Temporary scroller */}
                <div id="scroller">
                    <button type="button" className="btn btn-transparent" onClick={ () => scrollTo('#bento') }>
                        <i className="bx bxs-chevrons-down bx-sm"></i>
                    </button>
                </div>
            </section>

            {/* <HeroBanner /> */}

            <section id="bento">
                <div className="container">
                    <div className="bento-layout">
                        <div className="bento-item w-12 w-lg-6 h-2">
                            <h1>Feedback</h1>
                            <div className="w-50">Gather valuable inputs to bring your ideas to their full potential</div>
                        </div>
                        <div className="bento-item w-12 w-md-6 h-2 h-lg-1">
                            <h1>Accountability</h1>
                            <div>Share your progress as you go</div>
                        </div>
                        <div className="bento-item w-12 w-md-6 h-2 h-lg-1">
                            <h1>Learning</h1>
                            <div className="w-50 w-md-100 w-lg-50">Acquire knowledge for personal growth</div>
                        </div>
                        <div className="bento-item w-12 w-lg-8 h-2">
                            <h1>Marketing</h1>
                            <div className="w-50 w-md-75 w-lg-50">Build your brand, promote your projects, and reach future users</div>
                        </div>
                        <div className="bento-item w-12 w-lg-4 h-2">
                            <h1>Networking</h1>
                            <div className="w-50 w-md-100">Connect with peers for collaborations, opportunities, and support</div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default App;
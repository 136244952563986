import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
    apiKey: "AIzaSyDApcaz_mlWkComYLQ9h-0aX3eSck_Qo5g",
    authDomain: "the-public-bay.firebaseapp.com",
    projectId: "the-public-bay",
    storageBucket: "the-public-bay.appspot.com",
    messagingSenderId: "21306121228",
    appId: "1:21306121228:web:d69c54a3c980ab87baa229",
    // measurementId: "G-Z0K8J20CTN"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const db = getFirestore(app);
const perf = getPerformance(app);

export { analytics, db, perf };